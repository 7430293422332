import * as React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { IoLogoApple, IoLogoAndroid } from 'react-icons/io'
import { classNames } from 'lib/shared-ui'
import { useNavigationItems } from './use-navigation-items'
import { MdStar } from 'react-icons/md'
import config from '../../../config'

interface DropdownMenuProps {
    name: string
    Icon?: Relocify.Icon
    isNew?: boolean
    isDisabled?: boolean
    items: {
        isNew?: boolean
        isStarred?: boolean
        isDisabled?: boolean
        name: string
        href?: string
    }[]
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
    name,
    items,
    isNew,
    isDisabled,
    Icon,
}) => {
    const router = useRouter()
    const [folded, setFolded] = React.useState(() => {
        return !items.find((item) => item.href === router.asPath)
    })

    return (
        <div>
            <MenuItem
                onClick={() => setFolded((isFolded) => !isFolded)}
                Icon={Icon}
                name={name}
                isNew={isNew}
                isDisabled={isDisabled}
                withArrow
                isSecondary
            />
            <div
                className={classNames(
                    folded ? 'hidden' : 'visible',
                    'flex flex-col'
                )}
            >
                {items.map((item) => (
                    <MenuLink
                        key={item.href}
                        path={item.href}
                        name={item.name}
                        isNew={item.isNew}
                        isStarred={item.isStarred}
                        isDisabled={item.isDisabled}
                        isSubItem
                        isSecondary
                    />
                ))}
            </div>
        </div>
    )
}

interface MenuItemProps {
    name: string
    path?: string
    Icon?: Relocify.Icon
    withArrow?: boolean
    isNew?: boolean
    isStarred?: boolean
    onClick?: () => void
    isSubItem?: boolean
    isSecondary?: boolean
    isDisabled?: boolean
}

const MenuItem: React.FC<MenuItemProps> = ({
    path,
    Icon,
    name,
    withArrow,
    isNew,
    isStarred,
    onClick,
    isSubItem,
    isDisabled,
    isSecondary,
}) => {
    const router = useRouter()
    const isActive = router.asPath === path

    return (
        <div
            className={classNames(
                isActive
                    ? 'bg-slate-200 text-gray-900 font-semibold'
                    : 'hover:bg-gray-50',
                withArrow ? 'justify-between' : '',
                isSubItem ? 'ml-4' : '',
                isSecondary ? 'text-sm' : 'text-base',
                isSubItem ? 'font-base' : 'font-medium',
                isDisabled
                    ? 'text-gray-400'
                    : 'text-gray-600 hover:text-gray-900',
                'group flex items-center px-2 py-2 rounded-md cursor-pointer'
            )}
            onClick={onClick}
        >
            <div className="flex flex-row items-center">
                {Icon && (
                    <Icon
                        className={classNames(
                            'h-5 w-5 mr-3',
                            isSecondary ? '' : 'mt-0.5'
                        )}
                    />
                )}
                {name}
                {isNew && (
                    <span className="flex h-2 w-2 relative mt-1 ml-1">
                        <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500" />
                    </span>
                )}
                {isStarred && (
                    <MdStar
                        className={classNames(
                            'h-3 w-3 ml-1.5',
                            isActive ? 'text-gray-600' : 'text-indigo-600'
                        )}
                    />
                )}
            </div>
            {withArrow && <ChevronDownIcon className="w-5 h-5 text-gray-600" />}
        </div>
    )
}

const MenuLink: React.FC<Omit<MenuItemProps, 'withArrow'>> = ({
    path,
    Icon,
    name,
    isNew,
    isStarred,
    isSubItem,
    isDisabled,
    isSecondary,
}) => {
    return (
        <Link href={path as string} passHref>
            <a
                target={path?.startsWith('https://') ? '_blank' : ''}
                rel="noopener noreferrer"
            >
                <MenuItem
                    name={name}
                    path={path}
                    isNew={isNew}
                    isStarred={isStarred}
                    Icon={Icon}
                    isSubItem={isSubItem}
                    isDisabled={isDisabled}
                    isSecondary={isSecondary}
                />
            </a>
        </Link>
    )
}

export const Navigation: React.FC = () => {
    const navigation = useNavigationItems()

    return (
        <>
            <nav className="flex flex-1 pb-4 pt-3 flex-col h-full">
                <div className="px-4">
                    <div className="flex flex-col">
                        {navigation.map(
                            ({
                                children,
                                isNew,
                                isStarred,
                                isDisabled,
                                name,
                                href,
                                Icon,
                            }) =>
                                children ? (
                                    <DropdownMenu
                                        items={children}
                                        name={name}
                                        Icon={Icon}
                                        isNew={isNew}
                                        isDisabled={isDisabled}
                                        key={name}
                                    />
                                ) : !href && !children ? (
                                    <MenuItem
                                        name={name}
                                        isDisabled={true}
                                        isSecondary
                                    />
                                ) : (
                                    <MenuLink
                                        key={href}
                                        path={href}
                                        Icon={Icon}
                                        name={name}
                                        isNew={isNew}
                                        isStarred={isStarred}
                                        isDisabled={isDisabled}
                                        isSecondary
                                    />
                                )
                        )}
                    </div>
                </div>

                <>
                    <div className="w-full border-t border-gray-200 my-6" />

                    <div className="px-4">
                        <h3 className="ml-1 mb-2 text-xs font-semibold text-gray-800 uppercase tracking-wider">
                            Mobile Apps
                        </h3>
                        <MenuLink
                            Icon={IoLogoApple}
                            name="Download for iOS"
                            path={config.apps.ios}
                            isSecondary
                        />
                        <MenuLink
                            Icon={IoLogoAndroid}
                            name="Download for Android"
                            path={config.apps.android}
                            isSecondary
                        />
                    </div>
                </>
            </nav>
        </>
    )
}
