import {
    AcademicCapIcon,
    BriefcaseIcon,
    CreditCardIcon,
    CubeTransparentIcon,
    FlagIcon,
    HomeIcon,
    MapIcon,
    SparklesIcon,
    UserGroupIcon,
} from '@heroicons/react/outline'

export interface NavigationItem {
    name: string
    href?: string
    isNew?: boolean
    isStarred?: boolean
    isDisabled?: boolean
    Icon?: Relocify.Icon
    children?: {
        name: string
        href?: string
        isNew?: boolean
        isStarred?: boolean
        isDisabled?: boolean
        Icon?: Relocify.Icon
    }[]
}

export const employerKnowledgeBaseNavigation: NavigationItem[] = [
    {
        name: 'Getting Started',
        href: '/employer/app',
        Icon: SparklesIcon,
    },
    {
        name: 'Service',
        href: '/employer/service',
        Icon: CubeTransparentIcon,
    },
    {
        name: 'Payments',
        href: '/employer/payments',
        Icon: CreditCardIcon,
    },
]

export const customerKnowledgeBaseNavigation: NavigationItem[] = [
    {
        name: 'Housing',
        Icon: HomeIcon,
        children: [
            { name: 'Onboarding', href: '/' },
            {
                name: 'Rental Process',
                href: '/rental-process',
                isStarred: true,
            },
            {
                name: 'Archiving Reasons',
                href: '/archiving-reasons',
                isStarred: true,
            },
            {
                name: 'Utilities',
                href: '/utilities',
                isStarred: true,
            },
            {
                name: 'Moving HHGs',
                href: '/moving-services',
            },
            { name: 'Furnishing Apartment', href: '/furnishing' },
            { name: 'Amsterdam', href: '/amsterdam' },
        ],
    },
    {
        name: 'Essentials',
        Icon: CreditCardIcon,
        children: [
            { name: 'BSN', href: '/essentials/bsn', isStarred: true },
            {
                name: 'Banking',
                href: '/essentials/banking',
                isStarred: true,
            },
            {
                name: 'Insurance',
                href: '/essentials/insurance',
                isStarred: true,
            },
            { name: 'TB Test', href: '/essentials/tb-test' },
            {
                name: 'Air Raid Sirens',
                href: '/essentials/air-raid-sirens',
            },
        ],
    },
    {
        name: 'Transportation',
        Icon: MapIcon,
        children: [
            { name: 'Bicycle', href: '/transportation/bicycle' },
            {
                name: 'Public Transportation',
                href: '/transportation/public-transport',
            },
            {
                name: 'Driving License',
                href: '/transportation/driving-license',
            },
            {
                name: 'Cars',
                href: '/transportation/cars',
            },
            {
                name: 'Parking',
                href: '/transportation/parking',
            },
        ],
    },
    {
        name: 'Settling In',
        Icon: FlagIcon,
        children: [
            { name: 'DigiD', href: '/settling-in/digid' },
            { name: 'MyGovernment', href: '/settling-in/my-government' },
            { name: 'Healthcare System', href: '/settling-in/healthcare' },
            { name: 'Shopping', href: '/settling-in/shopping' },
            { name: 'Well-being', href: '/settling-in/well-being' },
            {
                name: 'News Sources',
                href: '/settling-in/news-sources',
            },
            { name: 'Entertainment', href: '/settling-in/entertainment' },
            {
                name: 'Buying Property',
                href: '/settling-in/buying-property',
            },
        ],
    },
    {
        name: 'Education',
        Icon: AcademicCapIcon,
        children: [
            { name: 'Introduction', href: '/education/introduction' },
            { name: 'Primary Education', href: '/education/primary' },
            { name: 'Secondary Education', href: '/education/secondary' },
            {
                name: 'International Schools',
                href: '/education/international-schools',
            },
        ],
    },
    {
        name: 'Family & Pets',
        Icon: UserGroupIcon,
        children: [
            {
                name: 'Daycare',
                href: '/family-and-pets/daycare',
            },
            {
                name: 'Child Sports',
                href: '/family-and-pets/child-sports',
            },
            {
                name: 'Nannies',
                href: '/family-and-pets/nannies',
            },
            { name: 'Pets', href: '/family-and-pets/pets' },
        ],
    },
    {
        name: 'Work',
        Icon: BriefcaseIcon,
        children: [
            { name: 'Introduction', href: '/work/intro' },
            { name: 'Finding Job', href: '/work/finding-job' },
            // { name: 'Freelancing', href: '/work/freelancing' },
            {
                name: 'Tax & Soc. Security',
                href: '/work/taxes',
            },
        ],
    },
    {
        name: 'Integration',
        Icon: SparklesIcon,
        children: [
            { name: 'Dutch Language', href: '/integration/language' },
            { name: 'Dutch Culture', href: '/integration/culture' },
            { name: 'Short History', href: '/integration/history' },
            { name: 'Dutch Art', href: '/integration/art' },
        ],
    },
    // { name: 'Changelog', href: '/changelog' },
]

export const adminKnowledgeBaseNavigation = [
    {
        name: 'For Expats',
    },
    ...customerKnowledgeBaseNavigation,
    {
        name: 'For HR Teams',
    },
    ...employerKnowledgeBaseNavigation,
]

export function useNavigationItems(): NavigationItem[] {
    return customerKnowledgeBaseNavigation
}
