import { useRouter } from 'next/router'
import * as React from 'react'
import { logEvent } from './log-event'

export function useLogOnRender(name: string, props?: Record<string, any>) {
    const router = useRouter()
    const log = () => {
        setTimeout(() => {
            logEvent(name, props || {})
        }, 500)
    }

    React.useEffect(() => {
        if (router.isReady) {
            log()
        }
    }, [router.isReady, name])
}
