import * as React from 'react'
import type { AppProps } from 'next/app'
import { SessionProvider } from 'next-auth/react'
import { MDXProvider } from '@mdx-js/react'
import { components as MDXComponents } from 'lib/mdx'
import { AnalyticsTag } from 'lib/analytics'

import 'lib/styles/global.css'

export default function App({
    Component,
    pageProps: { session, ...pageProps },
}: AppProps) {
    return (
        <SessionProvider session={session}>
            <MDXProvider components={MDXComponents}>
                <AnalyticsTag />
                <Component {...pageProps} />
            </MDXProvider>
        </SessionProvider>
    )
}
