import * as React from 'react'
import TagManager from 'react-gtm-module'
import amplitude from 'amplitude-js'
import { useRouter } from 'next/router'
import config from 'config'

const initAmplitude = (apiKey: string, userId?: string) => {
    amplitude.getInstance().init(apiKey, userId, {
        includeUtm: true,
        includeReferrer: true,
        includeGclid: true,
        includeFbclid: true,
    })
}

export const AnalyticsTag: React.FC = () => {
    const router = useRouter()

    React.useEffect(() => {
        if (router.isReady && config.analyticsEnabled) {
            initAmplitude(config.amplitude.apiKey)

            TagManager.initialize({
                gtmId: config.gtmId,
            })
        }
    }, [router.isReady])

    return <></>
}
