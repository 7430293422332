import TagManager from 'react-gtm-module'
import amplitude from 'amplitude-js'

export function logEvent(name: string, props?: Record<string, any>) {
    console.log('Logged event to GTM', name, props)
    amplitude.getInstance().logEvent(name, props || {})

    TagManager.dataLayer({
        dataLayer: {
            event: name,
            ...props,
        },
    })
}
