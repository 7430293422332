import * as React from 'react'
import { classNames } from '../shared-ui'

export interface ImageProps {
    src: string
    alt: string
}

export function imagesUrl(path: string) {
    return `/img/${path}`
}

export const Image: React.FC<ImageProps> = ({ src, alt }) => {
    return (
        <>
            <img
                src={imagesUrl(src)}
                alt={alt}
                className={classNames(
                    'rounded-md my-5',
                    alt && alt.indexOf('bordered') > -1
                        ? 'border border-gray-200'
                        : '',
                    alt && alt.indexOf('centered') > -1
                        ? 'w-full max-w-2xl mx-auto'
                        : ''
                )}
            />
        </>
    )
}
