import * as React from 'react'

export interface GalleryProps {
    images: Record<string, string>
}

export function imagesUrl(path: string) {
    return `/img/${path}`
}

export const Gallery: React.FC<GalleryProps> = ({ images }) => {
    const imagesArray = Object.entries(images)

    return (
        <>
            <ul
                role="list"
                className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 my-5 mx-1"
            >
                {imagesArray.map(([src, label]) => (
                    <li key={src} className="relative">
                        <a
                            href={imagesUrl(src)}
                            target="_blank"
                            rel="noreferrer"
                            className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
                        >
                            <img
                                src={imagesUrl(src)}
                                alt=""
                                className="pointer-events-none object-cover group-hover:opacity-75"
                            />
                        </a>
                        <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
                            {label}
                        </p>
                    </li>
                ))}
            </ul>
        </>
    )
}
