import * as React from 'react'
import dayjs from 'dayjs'
import utcPlugin from 'dayjs/plugin/utc'
import relativeTimePlugin from 'dayjs/plugin/relativeTime'

dayjs.extend(utcPlugin)
dayjs.extend(relativeTimePlugin)

interface Props {
    date: string
    format: string
    fromLocal?: boolean
    className?: string
}

export function utcFormat(input: string | Date, format: string) {
    return dayjs.utc(input).local().format(format)
}

export const DateFormat: React.FC<Props> = ({
    date,
    format,
    className,
    fromLocal,
}) => {
    const formattedDate = React.useMemo(
        () =>
            fromLocal ? dayjs(date).format(format) : utcFormat(date, format),
        [date, format, fromLocal]
    )

    return <span className={className}>{formattedDate}</span>
}

export const TimeAgo: React.FC<{
    className?: string
    date: dayjs.ConfigType
    withoutSuffix?: boolean
}> = ({ className, date, withoutSuffix }) => (
    <span className={className}>{dayjs.utc(date).fromNow(withoutSuffix)}</span>
)
