export default {
    analyticsEnabled:
        (process.env.NEXT_PUBLIC_ENABLE_ANALYTICS as string) === 'true',
    baseUrl: process.env.NEXTAUTH_URL as string,
    services: {
        redar: {
            baseUrl: process.env.REDAR_BASE_URL,
        },
    },
    amplitude: {
        apiKey: '127e72a08a594d038656516bb021f04b',
    },
    uploadCare: {
        template: `https://ucarecdn.com/:id/:operations/`,
        proxyTemplate: `https://edc33e21ddb7b1a4cd9a.ucr.io/:operations/:url`,
    },
    sentry: {
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN as string,
    },
    redox: {
        selfViewings:
            'https://relocify.notion.site/Guide-for-Self-Viewings-e741a800ebf34154912c0a25c99e9c68?pvs=25',
    },
    gtmId: 'GTM-M3WLKDX',
    pricing: {
        individual: {
            viewing: 86,
            viewingInPerson: 34,
            offer: 780,
            plus: 1860,
            premium: 2550,
            url: 'https://www.relocify.nl/pricing?type=relocation',
            packages: [
                {
                    id: 'premium',
                    title: 'At once',
                    description: '€1,799/incl. VAT',
                    // value: '€1,799/incl. VAT',
                },
                {
                    id: 'premium3',
                    title: 'Over 3 months',
                    description: '€599 x 3/incl. VAT',
                    // value: '€599 x 3/incl. VAT',
                },
                {
                    id: 'paid-by-employer',
                    title: 'Paid by the employer',
                    description: '€0 for you',
                    value: '',
                },
            ],
        },
        company: {
            url: 'https://www.relocify.nl/pricing?type=relocation',
            packages: [
                {
                    id: 'single',
                    title: 'Single Relocation',
                    description: 'Pay as you go',
                    value: '€1,760 each',
                },
                {
                    id: '5-relocations',
                    title: '5 Relocations',
                    description: 'Most Popular - Save 12%',
                    value: '€1,550 each',
                },
                {
                    id: '10-relocations',
                    title: '10 Relocations',
                    description: 'Best Value - Save 25%',
                    value: '€1,320 each',
                },
            ],
            extras: [
                {
                    id: 'shuttle-service',
                    title: 'Airport Shuttle',
                    description: 'With one hour wait in the airport',
                    value: 'From €70',
                },
                {
                    id: 'schooling-service',
                    title: 'Schooling service',
                    description:
                        'Advice and enrollment of the kids into local school',
                    value: '€480',
                },
                {
                    id: 'bsn-appointment',
                    title: 'BSN appointment',
                    description: 'BSN or combined appointment at IN Amsterdam',
                    value: '€75',
                },
            ],
        },
    },
    legal: {
        tos: 'https://www.relocify.nl/legal/tos',
        privacy: 'https://www.relocify.nl/legal/privacy',
    },
    calendly: 'https://cal.com/relocify/consultation-in-app',
    mapToken: process.env.NEXT_PUBLIC_MAPBOX_TOKEN as string,
    apps: {
        ios: 'https://apps.apple.com/app/id1667957577',
        android:
            'https://play.google.com/store/apps/details?id=com.relocify.remobil',
    },
    guestRoutes: {
        client: [
            '/auth/signin',
            '/invitation/',
            '/auth/signup',
            '/auth/error',
            '/auth/forgot',
        ],
        server: ['auth/sign-up', 'auth/invitations/accept', 'auth/forgot'],
    },
    publicRoutes: {
        client: ['/presentations/', '/agencies/'],
        server: ['presentations/', 'agencies/'],
    },
}
